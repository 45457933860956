import React, { useState } from "react";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import RsvpTab from '../pages/tabs/RsvpTab';
import WeddingPartyTab from '../pages/tabs/WeddingPartyTab';
import AccomodationTab from '../pages/tabs/AccomodationTab';
import FaqTab from '../pages/tabs/FaqTab';
import OrderOfTheDayTab from '../pages/tabs/OrderOfTheDayTab';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/material/IconButton';
import { DialogActions, DialogContent, DialogContentText } from "@mui/material";

export default function RvspContent() {
    const [openDialog, setOpenDialog] = useState(false);
    const [openTab, setOpenTab] = useState(false);
    const [dialogContents, setDialogContents] = useState(null);
    const [tabContents, setTabContents] = useState(null);

    const handleDialogClose = () => {
        setOpenDialog(false);
    }

    const handleDialogOpen = (tabToLoad) => {
        setDialog(tabToLoad);
        setOpenDialog(true);
    }

    const handleTabOpen = (tabToLoad) => {
        setTab(tabToLoad);
        setOpenTab(true);
    }

    const setDialog = (tab) => {
        if (tab === "rsvpTab") {
            setDialogContents(<RsvpTab />);
        }
    }

    const setTab = (tab) => {
        if (tab === "rsvpTab") {
            setTabContents(<RsvpTab />);
        } else if (tab === "orderOfTheDayTab") {
            setTabContents(<OrderOfTheDayTab />);
        } else if (tab === "weddingPartyTab") {
            setTabContents(<WeddingPartyTab />)
        } else if (tab === "accomodationTab") {
            setTabContents(<AccomodationTab />);
        } else if (tab === "faqTab") {
            setTabContents(<FaqTab />);
        }
    }

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    '& > *': {
                        m: 1,
                    },
                }}>
                <ButtonGroup variant="text" aria-label="text button group">
                    <Button onClick={() => { handleDialogOpen("rsvpTab") }}>RSVP</Button>
                    <Button onClick={() => { handleTabOpen("orderOfTheDayTab") }}>The Day</Button>
                    {/*<Button onClick={() => { handleTabOpen("weddingPartyTab") }}>Wedding Party</Button>*/}
                    <Button onClick={() => { handleTabOpen("accomodationTab") }}>Accomodation</Button>
                    <Button onClick={() => { handleTabOpen("faqTab") }}>FAQ</Button>
                </ButtonGroup>
            </Box>
            <div id="tabContents">
                {tabContents}
            </div>
            <Dialog open={openDialog} onClose={() => handleDialogClose()}>
            <Button sx={{ color: 'rgb(156, 175, 136)', paddingLeft: '75%' }} onClick={() => handleDialogClose()}> Close</Button> 
                {dialogContents}
            </Dialog>
        </div>
    );
}
