import * as React from 'react';
import DialogContent from '@mui/material/DialogContent';


export default class FaqTab extends React.Component {

    render() {
        return (   
            <>
            <div id="faqDetails">
                <center>
                <p class="greenText"><b>Is there a dress code?</b></p>
                <p class="whiteText">We would love to see our friends and family get dressed up with us! Our dress code is cocktail attire. No jeans or trainers, please.<br/>
                The wedding ceremony and reception should be indoors, with plenty of air conditioning. However, if the weather is nice, <br/>
                we have the option to move either outdoors.
                </p>
                <br/>
                <p class="greenText"><b>Can I bring my children?</b></p>
                <p class="whiteText">We're very sorry, but due to space restrictions, we cannot accomodate guests under 18 on our wedding day. We encourage <br/>
                you to use this evening to have a fun date night.</p>
                <br/>
                <p class="greenText"><b>What if I don't RSVP?</b></p>
                <p class="whiteText">To help us with planning, we kindly ask that you RSVP by the date provided on your formal invitation. If you are having trouble RSVPing <br/>
                via the website, please contact the Bride or Groom. Unfortunately, if we do not receive your RSVP by the deadline noted on the invitation. <br/>
                We will have to mark you as 'Not Attending'. We will miss celebrating with you, but we do need to finalise guest count for the venue and our <br/>
                vendors. We cannot make any exceptions. We hope that you understand!</p>
                <br/>
                <p class="greenText"><b>Can I bring a +1?</b></p>
                <p class="whiteText">Our wedding is strictly invitation only. If you have a +1 indicated on your formal invitation and RSVP form, then you are welcome to bring <br/>
                a +1! Unfortunately, we cannot accomodate other extra guests, due to venue capacity.</p>
                <br/><p class="greenText"><b>When should I arrive?</b></p>
                <p class="whiteText">The ceremony is due to start at 2pm. We recommend arriving at 1.30pm, to ensure everyone is seated and we can start on schedule. </p>
                <br/>
                <p class="greenText"><b>Can I bring a gift?</b></p>
                <p class="whiteText">Your presence at the wedding is enough and a gift is not necessary. However, if you wish to gift us something, we would <br/>
                greatly appreciate a contribution towards our dream honeymoon. We will have a cards box at the venue.</p>
                <br/><p class="greenText"><b>Is there any accomodation on or offsite?</b></p>
                <p class="whiteText">There is accomodation onsite. Aswell as hotels around the local area. Please refer to the 'Accomodation' page on the website for this.</p>
                <br/>
                <p class="greenText"><b>Where can I park?</b></p>
                <p class="whiteText">There is free onsite parking. Please follow the signs for 'Tippen Wedding' on arrival. There are plenty of spaces, so it shouldn't be an issue.</p>
                <br/><p class="greenText"><b>Can I take photographs during the ceremony?</b></p>
                <p class="whiteText">Our ceremony will be an 'unplugged' ceremony. This means no phones or cameras to allow our photographer to capture the best memories. <br/>
                We politely ask that all our guests follow this. However, please feel free to take as many photos as you like for the rest of the day!</p>
                <br/>
                </center>
            </div>
            </>
        );
    }
}