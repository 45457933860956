import './App.css';
import Home from './pages/Home';
import SaveTheDate from './pages/SaveTheDate';
import Rsvp from './pages/Rsvp';
import videoBackground from './assets/beaumont.mp4'
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

function Index() {
  return (
    <div>
     <SaveTheDate />
    </div>
  );
}

function SaveDate() {
  return (
    <div>
     <SaveTheDate />
    </div>
  );
}

function Reply() {
  return (
    <div>
     <Rsvp />
    </div>
  );
}

export default function App() {
  return (
    <div>
      <div className='main'>
        <div className="content">
          <h1 style={{color: '#9CAF88'}}>Lauren & Jacks Wedding</h1>
          <Router>
            <Routes>
              <Route
                path="/"
                element={<Index />}
              />
              <Route
                path="savethedate"
                element={<SaveDate />}
              />
              <Route
                path="rsvp"
                element={<Reply />}
              />
          </Routes>
        </Router>
        <div className="pageDetails">
          
        </div>
        </div>
      </div>
      <div className='screen'>
        <div className="overlay"/>
          <video src={videoBackground} autoPlay loop muted playsInline/>
        </div>
    </div>
  );
}