import React from "react";
import Countdown from '../components/Countdown';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import '../fonts/BodoniFLF-Roman.ttf';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const Home = () => (
    <div>
        <h1> Save The Date </h1>
        <Countdown/>
        <div className="calendar">
        <center>
        <AddToCalendarButton
            name="Lauren and Jacks Wedding"
            description="Lauren and Jacks Wedding at the De Vere Beaumont Estate in Windsor"
            options={['Apple','Google']}
            location="Windsor De Vere Beaumont Estate"
            startDate="2025-07-04"
            endDate="2025-07-04"
            size="5"
            lightMode="dark"
            styleDark="--btn-background: #000;"
            buttonStyle="date"
            timeZone="Europe/London">
        </AddToCalendarButton>
        </center>
        </div>
    </div> 
             
);

export default Home;