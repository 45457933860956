import * as React from 'react';
import DialogContent from '@mui/material/DialogContent';
import CustomizedTimeline from '../../components/CustomizedTimeline';


export default class OrderOfTheDayTab extends React.Component {

    render() {
        return (   
                <CustomizedTimeline />
        );
    }
}