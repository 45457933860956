import * as React from 'react';
import SimpleImageSlider from "react-simple-image-slider";
import image1 from "../../assets/images/bridegroom.png";
import image2 from "../../assets/images/bestmen.png";
import image3 from "../../assets/images/maidsofhonour.png";
import image4 from "../../assets/images/groomsman.png";

const images = [
    { url: image1 },
    { url: image2 },
    { url: image3 },
    { url: image4 },
  ];

export default class WeddingPartyTab extends React.Component {

    render() {
        return (   
            <div id="carousel">
            <SimpleImageSlider
              width={600}
              height={174}
              images={images}
              showBullets={false}
              showNavs={true}
              slideDuration={5}
              autoPlay={true}
              navMargin={-5}
            />
          </div>
        );
    }
}