import React, { useState } from 'react';
var Spinner = require('react-spinkit');

export default class RsvpTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          loading: true
        };
      }
    hideSpinner = () => {
        this.setState({
          loading: false
        });
    };

    render() {
        return (   
            <div id="rsvpify-wrapper">
              {this.state.loading ? (
                <center>
                <Spinner
                  className="loading text-center"
                  name="folding-cube"
                  color="rgb(156, 175, 136)"
                  fadeIn="half"
                />
                </center>
        ) : null}
        <iframe src="https://tippenwedding.rsvpify.com?embed=1&securityToken=E62bX6WU3flsnamFQB6ltcGfyy3fSVTi" onLoad={this.hideSpinner} allowtransparency="true" style={{width: "100%", height: "100%", minHeight: "400px", minWidth: "100%", border: "none", overflow: "none", display: "block", backgroundColor: "transparent !important", background: "none transparent !important"}}/>
        </div>
        );
    }
}