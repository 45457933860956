import * as React from 'react';
import DialogContent from '@mui/material/DialogContent';


export default class AccomodationTab extends React.Component {

    render() {
        return (   
            <>
            <div id="accomodationDetails">
                <center>
                <p class="greenText"><b>Accomodation at the venue</b></p>
                <p class="whiteText">If you would like accomodation at the venue. There are a limited number of rooms offered at a discounted rate of £x per night. <br/>
                To book one of these rooms please call xx and quote xx. </p>
                <br/>
                <p class="greenText"><b>Other Hotels</b></p>
                <div style={{maxWidth: '100%', listStyle: 'none', transition: 'none', overflow: 'hidden', width: '500px', height: '300px'}}><div id="my-map-display" style={{height: '100%', width: '100%', maxWidth: '100%'}}><iframe style={{height: '100%', width: '100%', border: '0'}} frameborder="0" src="https://www.google.com/maps/embed/v1/search?q=hotels+nearby+de+vere+beaumont+estate+windsor&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe></div><a class="google-map-html" href="https://kbj9qpmy.com/bp" id="get-data-for-embed-map">Broadband Providers</a></div>
                </center>
            </div>
            </>
        );
    }
}