import React from "react";
import RsvpContent from '../components/RsvpContent';

const Rsvp = () => (
    <div>
        <h1> Invitation RSVP </h1>
        <RsvpContent />
    </div>
);

export default Rsvp;